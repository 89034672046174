import React from "react";

export const VideoRelease = ({ url }) => {
  return (
    <div className="video-release">
      <iframe
        className="iframe-item"
        src={url}
        width="860"
        height="484"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  );
};
