import axios from "axios";
import { baseUrl } from "../utils/config.js";

const getDiscography = async () => {
  const response = await axios.get(
    `${baseUrl}/release-groups?sort=id:asc&populate[releases][populate]=*`
  );
  return response.data.data;
};

export default { getDiscography };
