import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { motion, useAnimation } from "framer-motion";

const transitionDuration = 0.8;
const quoteDisplayDuration = 4000;

const animations = {
  enter: {
    opacity: 1,
    filter: "blur(0px)",
    transition: {
      ease: "easeIn",
      duration: transitionDuration,
    },
    visibility: "visible",
  },
  exit: {
    opacity: 0,
    filter: "blur(10px)",
    transition: {
      ease: "easeOut",
      duration: transitionDuration,
    },
    transitionEnd: {
      visibility: "hidden",
    },
  },
};

const progressAnimation = {
  start: {
    scaleX: 0,
  },
  end: {
    transition: {
      duration: quoteDisplayDuration / 1000,
      ease: "linear",
    },
    scaleX: 1,
  },
};

export const QuoteSlider = ({ quotes }) => {
  const [activeQuote, setActiveQuote] = useState(0);
  const mounted = useRef(false);
  const progressBarControls = useAnimation();
  const n = quotes.length;
  const reset = true;

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  const timerRef = useRef(null);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setActiveQuote((prev) => (prev + 1) % n);
    }, quoteDisplayDuration);
    if (mounted.current) {
      progressBarControls.set("start");
    }
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    if (mounted.current) {
      progressBarControls.set("end");
    }
  };

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, [n]);

  return (
    <>
      <motion.div
        className="reviews"
        style={{ position: "relative" }}
        onMouseEnter={() => {
          stopTimer();
        }}
        onMouseLeave={() => {
          startTimer();
        }}
      >
        {quotes.map(({ text, author }, index) => (
          <motion.blockquote
            key={index}
            initial="exit"
            animate={activeQuote === index ? "enter" : "exit"}
            variants={animations}
            style={{ padding: "1em" }}
          >
            <ReactMarkdown children={text} />
            <footer>
              <cite>- {author}</cite>
            </footer>
          </motion.blockquote>
        ))}
      </motion.div>
      <motion.div
        className="review-progress-bar"
        initial="start"
        animate={progressBarControls}
        variants={progressAnimation}
      />
    </>
  );
};
