import React, { useEffect } from "react";
import AlbumCover from "../../../static/images/album-cover.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { Reveal } from "../Reveal";
import { ProgressBar } from "../ProgressBar";
import { QuoteSlider } from "../QuoteSlider";

import { initializeDiscography } from "../../reducers/discographyReducer";
import { useDispatch, useSelector } from "react-redux";
import { AboutReleases } from "../AboutReleases";
import { VideoRelease } from "../VideoRelease";

import StrapiImage from "../StrapiImage";

const streamingIconByName = (name) => {
  switch (name) {
    case "Apple":
      return <FontAwesomeIcon icon={faApple} />;
    case "Spotify":
      return <FontAwesomeIcon icon={faSpotify} />;
    default:
      return null;
  }
};

const Discography = () => {
  const discographyPageContents = useSelector((state) => state.discography);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeDiscography());
  }, []);

  return (
    <>
      <div className="discography">
        <ProgressBar />
        {discographyPageContents &&
          discographyPageContents.map((releaseGroup) => (
            <div key={releaseGroup.id} className="section-content">
              <Reveal className="discography-section-title">
                <h1>{releaseGroup.name}</h1>
              </Reveal>

              {releaseGroup.releases &&
                releaseGroup.releases.map((release) => (
                  <div key={release.id}>
                    <Reveal className="album-info">
                      {release.cover_art && (
                        // <img
                        //   src={`/strapi${release.cover_art.url}`}
                        //   alt="Album cover"
                        //   className="album-info-cover"
                        // />
                        <StrapiImage
                          image={release.cover_art}
                          className="album-info-cover"
                        />
                      )}

                      <div className="more-info">
                        <h3>
                          {release.title}
                          {release.year && ` (${release.year})`}
                        </h3>
                        {release.publisher && (
                          <p>
                            Released on{" "}
                            <a href={release.publisher.url} target="_blank">
                              {release.publisher.name}
                            </a>
                          </p>
                        )}
                        {release.streaming_links && (
                          <ul>
                            {release.streaming_links.map((link) => (
                              <li key={link.id}>
                                <a href={link.url} target="_blank">
                                  {link.platform}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </Reveal>

                    {release.about_release && (
                      <Reveal directionFrom="top">
                        <AboutReleases text={release.about_release} />
                      </Reveal>
                    )}

                    {release.video && (
                      <Reveal directionFrom="left">
                        <VideoRelease url={release.video} />
                      </Reveal>
                    )}

                    {release.reviews && release.reviews.length !== 0 && (
                      <Reveal directionFrom="top">
                        <QuoteSlider quotes={release.reviews} />
                      </Reveal>
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
      <a href="#" className="top">
        &#8593;
      </a>
    </>
  );
};

export default Discography;
