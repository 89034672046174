import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializeHomepage } from "../../reducers/homepageReducer";
import StrapiImage from "../StrapiImage";
import { setCssImageSourceFromStrapi } from "../../utils/backgroundImageHelper";

import { motion } from "framer-motion";

const HomePage = () => {
  useEffect(() => {
    const elem = document.getElementById("nav-name");

    if (elem) {
      elem.classList.remove("title-show");
      elem.classList.add("title-hide");
    }

    return () => {
      if (elem) {
        elem.classList.add("title-show");
        elem.classList.remove("title-hide");
      }
    };
  }, []);

  useEffect(() => {
    const back = document.getElementsByClassName("main-layout")[0];

    if (back) {
      back.classList.add("dark-background");
    }

    return () => {
      if (back) {
        back.classList.remove("dark-background");
      }
    };
  }, []);

  const homepageContent = useSelector((state) => state.homepage);
  const backgroundImage = homepageContent.BackgroundImage;

  const dispatch = useDispatch();

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    dispatch(initializeHomepage());
  }, []);

  return (
    <>
      {/* This image is always invisible and we are only using it 
      to trigger an onLoad handler when the source image was loaded. */}
      {backgroundImage && (
        <StrapiImage
          image={backgroundImage}
          onLoad={() => {
            setBackgroundLoaded(true);
            setCssImageSourceFromStrapi(backgroundImage, "landing-background");
          }}
          style={{ display: "none" }}
        />
      )}
      <motion.div
        className="background"
        initial={{ height: 0 }}
        animate={{ height: "100%" }}
        transition={{ ease: [0.7, -0.01, 0, 0.99], duration: 1 }}
        // exit={{ opacity: 0 }}
      >
        <h1 id="lower-left-title">Blaž Švagan</h1>
      </motion.div>
      <motion.main></motion.main>
    </>
  );
};

export default HomePage;
