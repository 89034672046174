import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "./Navbar";
import { Outlet } from "react-router";
import Footer from "./Footer";
import { HamburgerMenu } from "./HamburgerMenu";

const MainLayout = () => {
  const hambOpen = useSelector((state) => state.menu);

  return (
    <div className="main-layout">
      <div className="main-layout-upper">
        <HamburgerMenu>
          <Navbar />
        </HamburgerMenu>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
