import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faSpotify } from "@fortawesome/free-brands-svg-icons"
import { faApple } from "@fortawesome/free-brands-svg-icons"

const SocMedia = () => {
  return (
    <div className="soc-media">
      <a
        className="social-icon"
        href="https://www.instagram.com/blaz.svagan/"
        target="_blank"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className="social-icon"
        href="https://www.facebook.com/blaz.svagan.trio"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a
        className="social-icon"
        href="https://www.youtube.com/channel/UCsDPKZm36R_7SUGp46fNRVQ"
        target="_blank"
      >
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a
        className="social-icon"
        href="https://open.spotify.com/album/2PA85PfgzQug20IYIbP1oT"
        target="_blank"
      >
        <FontAwesomeIcon icon={faSpotify} />
      </a>
      <a
        className="social-icon"
        href="https://music.apple.com/us/artist/bla%C5%BE-%C5%A1vagan/1524542036"
        target="_blank"
      >
        <FontAwesomeIcon icon={faApple} />
      </a>
    </div>
  )
}

export default SocMedia
