import React from "react";
import { motion } from "framer-motion";

export const AnimatedHamburgerIcon = ({ isOpen, onClick }) => {
  const lineWidth = "0.14em";
  const imageHeight = "0.67em";
  const duration = 0.2;

  const upperLineVariants = {
    parallel: { x1: "3%", y1: "25%", x2: "97%", y2: "25%" },
    cross: { x1: "8%", y1: "8%", x2: "92%", y2: "92%" },
  };

  const lowerLineVariants = {
    parallel: { x1: "3%", y1: "75%", x2: "97%", y2: "75%" },
    cross: { x1: "8%", y1: "92%", x2: "92%", y2: "8%" },
  };

  return (
    <svg
      width={imageHeight}
      height={imageHeight}
      xmlns="http://www.w3.org/2000/svg"
      className="hamburger-menu-icon"
      onClick={onClick}
    >
      <motion.line
        variants={upperLineVariants}
        animate={isOpen ? "cross" : "parallel"}
        transition={{ duration, ease: "easeInOut" }}
        style={{ stroke: "rgb(33, 33, 33)", strokeWidth: lineWidth }}
      />
      <motion.line
        variants={lowerLineVariants}
        animate={isOpen ? "cross" : "parallel"}
        transition={{ duration, ease: "easeInOut" }}
        style={{ stroke: "rgb(33, 33, 33)", strokeWidth: lineWidth }}
      />
    </svg>
  );
};
