import React, { useEffect } from "react";
import "./Photos.css";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

import { initializePhotos } from "../../reducers/photosReducer";
import { useDispatch, useSelector } from "react-redux";

import StrapiImage from "../StrapiImage";

const Photos = () => {
  const photos = useSelector((state) => state.photos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializePhotos());
  }, []);

  useEffect(() => {
    const gallery = document.getElementsByClassName("gallery")[0];

    if (gallery) {
      gallery.classList.add("additional-z");
    }

    return () => {
      if (gallery) {
        gallery.classList.remove("additional-z");
      }
    };
  }, []);

  return (
    <div className="gallery">
      <div className="box">
        {[0, 1, 2].map((remainder, dreamIndex) => (
          <div key={dreamIndex} className="dream">
            {photos &&
              photos
                .map((imageDict, index) => ({ ...imageDict, index }))
                .filter((image, index) => index % 3 === remainder)
                .map((image, index) => (
                  <Link to={`./${image.index}`} key={image.id}>
                    <StrapiImage image={image} className="image" />
                  </Link>
                ))}
          </div>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default Photos;
