import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import StrapiImage from "../StrapiImage";

import { initializeAbout } from "../../reducers/aboutReducer";
import { useDispatch, useSelector } from "react-redux";

import { Reveal } from "../Reveal";

import { LoadingScreen } from "../LoadingScreen";

const About = () => {
  const aboutPageContents = useSelector((state) => state.about);
  const aboutContent = aboutPageContents.biography;
  const backgroundAboutPhoto = aboutPageContents.aboutPhoto;

  const showLoadingScreen = !(aboutContent && backgroundAboutPhoto);
  // const showLoadingScreen = true;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAbout());
  }, []);

  return (
    <>
      {showLoadingScreen && <LoadingScreen mode={"white"} />}
      <Reveal directionFrom="left">
        {backgroundAboutPhoto && (
          // <img
          //   className="about-blaz-img"
          //   src={`/strapi${backgroundAboutPhoto.url}`}
          //   alt="Blaz Svagan"
          // />
          <StrapiImage
            image={backgroundAboutPhoto}
            className="about-blaz-img"
          />
        )}
      </Reveal>
      <Reveal directionFrom="right">
        <div className="about-blaz">
          <ReactMarkdown children={aboutContent} />
        </div>
      </Reveal>
    </>
  );
};

export default About;
