import React, { useEffect, useState } from "react";
import AlbumCover from "../../../static/images/album-cover.jpg";
import { Link } from "react-router-dom";
import SocMedia from "../SocMedia";

import { motion } from "framer-motion";

import { initializeHomepage } from "../../reducers/homepageReducer";
import { useDispatch, useSelector } from "react-redux";
import StrapiImage from "../StrapiImage";

import { setCssImageSourceFromStrapi } from "../../utils/backgroundImageHelper";
import { imagePrefixUrl } from "../../utils/config";
import { LoadingScreen } from "../LoadingScreen";

const LandingPage = () => {
  const homepageContent = useSelector((state) => state.homepage);
  const coverImage = homepageContent.CoverImage;
  const backgroundImage = homepageContent.BackgroundImage;

  const dispatch = useDispatch();

  const [coverLoaded, setCoverLoaded] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    dispatch(initializeHomepage());
  }, []);

  const showLoadingScreen = !(coverLoaded && backgroundLoaded);
  // const showLoadingScreen = true;

  return (
    <div>
      {/* This image is always invisible and we are only using it 
      to trigger an onLoad handler when the source image was loaded. */}
      {backgroundImage && (
        <img
          src={`${imagePrefixUrl}${backgroundImage.formats.xl.url}`}
          onLoad={() => {
            setBackgroundLoaded(true);
            setCssImageSourceFromStrapi(backgroundImage, "landing-background");
          }}
          style={{ display: "none" }}
        />
      )}

      {/* Loading... */}
      {showLoadingScreen && <LoadingScreen mode={"black"} />}
      <motion.div
        className="background dark-background"
        animate={{}}
        exit={{
          filter: "brightness(0.2)",
        }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        style={{ display: !showLoadingScreen ? "block" : "none" }}
      >
        <div className="landing-page">
          <div className="album-cover">
            <a
              className="listen-album intro-text"
              href="https://www.autrecords.com/store/blaz-svagan-sound-in-time/"
              target="_blank"
            >
              Listen / Buy new album now!
            </a>
            <a
              // href="https://www.autrecords.com/store/blaz-svagan-sound-in-time/"
              target="_blank"
            >
              {coverImage && (
                <StrapiImage
                  image={coverImage}
                  onLoad={() => setCoverLoaded(true)}
                />
              )}
            </a>
            <div className="enter-web">
              <Link to="/pages">Enter</Link>
            </div>
            <br />
            <SocMedia />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LandingPage;
