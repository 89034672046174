import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import "modern-normalize/modern-normalize.css";
import "css-homogenizer/reset.css";
import "css-homogenizer/base.css";
import "./styles.css";
import "../static/fonts/Avenir/avenir.css";
import "../static/fonts/Atyp/atyp.css";

import { Provider } from "react-redux";
import store from "./store";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);
