import React from "react";
import SocMedia from "./SocMedia";

import { useSelector } from "react-redux";

const Footer = () => {
  const hambOpen = useSelector((state) => state.menu);

  return (
    <div className={"footer" + (hambOpen ? " menu-open" : " menu-closed")}>
      <SocMedia />
      <div className="copy">
        <span>All Rights Reserved.</span>
        <br />
        <span>Photography: Simon Zimič</span>
        <br />
        <span>
          Web design:{" "}
          <a href="https://nevenaradovic.me/" target="_blank">
            Nevena Radović
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
