import { createSlice } from "@reduxjs/toolkit";
import homepageService from "../services/homepage";

const homepageSlice = createSlice({
  name: "homepage",
  initialState: {},
  reducers: {
    setHomepage(state, action) {
      return action.payload;
    },
  },
});

const { setHomepage } = homepageSlice.actions;

export const initializeHomepage = () => {
  return async (dispatch) => {
    const homepage = await homepageService.getHomepage();
    dispatch(setHomepage(homepage));
  };
};

export default homepageSlice.reducer;
