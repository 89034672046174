// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: white;
}


.prev-next {
  color: var(--dark-fg);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Photos.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,YAAY;AACd;;;AAGA;EACE,qBAAqB;AACvB","sourcesContent":[".modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  overflow-y: hidden;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n\n.modal img {\n  max-width: 90%;\n  max-height: 90%;\n}\n\n.close {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  cursor: pointer;\n  color: white;\n}\n\n\n.prev-next {\n  color: var(--dark-fg);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
