import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Photos.css";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { initializePhotos } from "../../reducers/photosReducer";
import { useSwipeable } from "react-swipeable";
import StrapiImage from "../StrapiImage";

const Photo = () => {
  const photos = useSelector((state) => state.photos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializePhotos());
  }, []);

  const photoId = parseInt(useParams().photoId);
  const navigate = useNavigate();

  const prevPageLink = `../${(photoId - 1 + photos.length) % photos.length}`;
  const nextPageLink = `../${(photoId + 1) % photos.length}`;

  const gotoPrevPage = () => navigate(prevPageLink);
  const gotoNextPage = () => navigate(nextPageLink);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => gotoPrevPage(),
    onSwipedRight: () => gotoNextPage(),
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        gotoPrevPage();
      } else if (e.key === "ArrowRight") {
        gotoNextPage();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.body.style.overflow = "unset";
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <div className="modal" onClick={() => navigate("..")} {...swipeHandlers}>
      <Link
        to={prevPageLink}
        className="prev-next"
        onClick={(e) => e.stopPropagation()}
      >
        <ArrowBackIosNewSharpIcon />
      </Link>
      {photos.length && (
        <StrapiImage
          image={photos[photoId]}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      <Link
        to={nextPageLink}
        className="prev-next"
        onClick={(e) => e.stopPropagation()}
      >
        <ArrowForwardIosSharpIcon />
      </Link>
    </div>
  );
};

export default Photo;
