import { createSlice } from "@reduxjs/toolkit";
import menuService from "../services/about";

const menuSlice = createSlice({
  name: "menu",
  initialState: false,
  reducers: {
    openMenu(state, action) {
      return true;
    },
    closeMenu(state, action) {
      return false;
    },
    toggleMenu(state, action) {
      return !state;
    },
  },
});

export const { openMenu, closeMenu, toggleMenu } = menuSlice.actions;

export default menuSlice.reducer;
