import { createSlice } from "@reduxjs/toolkit";
import aboutService from "../services/about";

const aboutSlice = createSlice({
  name: "about",
  initialState: {},
  reducers: {
    setAbout(state, action) {
      return action.payload;
    },
  },
});

const { setAbout } = aboutSlice.actions;

export const initializeAbout = () => {
  return async (dispatch) => {
    const about = await aboutService.getAbout();
    dispatch(setAbout(about));
  };
};

export default aboutSlice.reducer;
