// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Avenir-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Avenir-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Avenir-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Avenir-Regular.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Avenir-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Avenir-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Avenir-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Avenir-Bold.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: local("Avenir Regular"), local("Avenir-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("svg");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: local("Avenir Bold"), local("Avenir-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("ttf"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("svg");
}
`, "",{"version":3,"sources":["webpack://./static/fonts/Avenir/avenir.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB;;;;yDAIyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB;;;gHAG4E;AAC9E","sourcesContent":["@font-face {\n  font-family: \"Avenir\";\n  font-style: normal;\n  font-weight: 400;\n  src: local(\"Avenir Regular\"), local(\"Avenir-Regular\"),\n    url(./Avenir-Regular.woff) format(\"woff\"),\n    url(./Avenir-Regular.woff2) format(\"woff2\"),\n    url(./Avenir-Regular.ttf) format(\"ttf\"),\n    url(./Avenir-Regular.svg) format(\"svg\");\n}\n\n@font-face {\n  font-family: \"Avenir\";\n  font-style: normal;\n  font-weight: 700;\n  src: local(\"Avenir Bold\"), local(\"Avenir-Bold\"),\n    url(./Avenir-Bold.woff) format(\"woff\"),\n    url(./Avenir-Bold.woff2) format(\"woff2\"),\n    url(./Avenir-Bold.ttf) format(\"ttf\"), url(./Avenir-Bold.svg) format(\"svg\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
