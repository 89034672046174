import React, { useEffect } from "react";

import { initializeVideos } from "../../reducers/videosReducer";
import { useDispatch, useSelector } from "react-redux";

const Videos = () => {
  const videos = useSelector((state) => state.videos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeVideos());
  }, []);

  return (
    <div className="iframe-grid">
      {videos &&
        videos.map((video) => (
          <iframe
            key={video.id}
            className="iframe-item"
            src={video.link}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        ))}
    </div>
  );
};

export default Videos;
