import React from "react";
import ReactMarkdown from "react-markdown";

export const AboutReleases = ({ text }) => {
  return (
    <div
      className="about-releases"
      style={{ position: "relative", padding: "1em" }}
    >
      <div className="about-releases-content">
        <ReactMarkdown children={text} />
      </div>
    </div>
  );
};
