import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { closeMenu } from "../reducers/menuReducer";

export const HamburgerMenu = ({ children }) => {
  const hambOpen = useSelector((state) => state.menu);

  const dispatch = useDispatch();

  const close = () => dispatch(closeMenu());

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
        delayChildren: 0.3,
      },
    },
  };

  const divInitial = { transform: "translateY(-100%)" };
  const divAnimate = { transform: "translateY(0)" };
  const divTransition = {
    ease: [0.7, -0.01, 0, 0.99],
    duration: dropdownDuration,
  };
  const divExit = {
    transform: "translateY(-100%)",
    transition: { delay: 0.5, duration: 0.2 },
  };

  const dropdownDuration = 0.6;

  return (
    <div className="whole-hamburger-menu">
      <AnimatePresence>
        {hambOpen && (
          <motion.div
            className="hamburger-menu-underlay"
            initial={divInitial}
            animate={divAnimate}
            transition={divTransition}
            exit={divExit}
          ></motion.div>
        )}
      </AnimatePresence>
      {children}
      <AnimatePresence>
        {hambOpen && (
          <motion.div
            className="hamburger-menu-invert-overlay"
            initial={divInitial}
            animate={divAnimate}
            transition={divTransition}
            exit={divExit}
          ></motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {hambOpen && (
          <div className="hamburger-menu-links">
            <motion.ul
              className="hamburger-links"
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              <motion.li variants={itemVariants} onClick={close}>
                <Link to="/pages/about">About</Link>
              </motion.li>
              <motion.li variants={itemVariants} onClick={close}>
                <Link to="/pages/discography">Discography</Link>
              </motion.li>
              <motion.li variants={itemVariants} onClick={close}>
                <Link to="/pages/photos">Photos</Link>
              </motion.li>
              <motion.li variants={itemVariants} onClick={close}>
                <Link to="/pages/videos">Videos</Link>
              </motion.li>
              <motion.li variants={itemVariants} onClick={close}>
                <Link to="/pages/contact">Contact</Link>
              </motion.li>
            </motion.ul>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
