import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export const Reveal = ({
  children,
  width = "fit-content",
  directionFrom = "left",
  ...other
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView]);

  const distance = 100;

  const directions = {
    left: [{ x: -distance }, { x: 0 }],
    right: [{ x: distance }, { x: 0 }],
    top: [{ y: -distance }, { y: 0 }],
    bottom: [{ y: distance }, { y: 0 }],
  };

  return (
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            ...directions[directionFrom][0],
            filter: "blur(10px)",
          },
          visible: {
            opacity: 1,
            ...directions[directionFrom][1],
            filter: "blur(0px)",
          },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.5 }}
        {...other}
      >
        {children}
      </motion.div>
    </div>
  );
};
