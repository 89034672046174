import React from "react";
import ReactLoading from "react-loading";

export const LoadingScreen = ({ mode }) => {
  return (
    <div
      style={{
        // display: showLoadingScreen ? "flex" : "none",
        display: "flex",
        width: "100vw",
        height: "100vh",
        // backgroundColor: "#212121",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={
        mode === "white" ? "loading-screen-white" : "loading-screen-black"
      }
    >
      <ReactLoading
        type={"cubes"}
        color={mode === "white" ? "#212121" : "#f2f2f4"}
        height={100}
        width={100}
      />
    </div>
  );
};
