import { createSlice } from "@reduxjs/toolkit";
import photosService from "../services/photos";

const photosSlice = createSlice({
  name: "photos",
  initialState: [],
  reducers: {
    setPhotos(state, action) {
      return action.payload;
    },
  },
});

const { setPhotos } = photosSlice.actions;

export const initializePhotos = () => {
  return async (dispatch) => {
    const photos = await photosService.getPhotos();
    dispatch(setPhotos(photos.photos));
  };
};

export default photosSlice.reducer;
