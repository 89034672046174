import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// pages
import MainLayout from "./components/MainLayout";
import LandingPage from "./components/pages/LandingPage";
import HomePage from "./components/pages/HomePage";
import Discography from "./components/pages/Discography";
import About from "./components/pages/About";
import Photos from "./components/pages/Photos";
import Photo from "./components/pages/Photo";
import Videos from "./components/pages/Videos";
import Contact from "./components/pages/Contact";

import { useSelector } from "react-redux";

const App = () => {
  const hambOpen = useSelector((state) => state.menu);

  useEffect(() => {
    document.body.removeAttribute("style");
  }, []);
  
  const location = useLocation();
  const locationArr = location.pathname?.split("/") ?? [];

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={locationArr[1]}
        className={"footer" + (hambOpen ? " menu-open" : " menu-closed")}
      >
        <Route path="/" element={<LandingPage />} />
        <Route path="/pages" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/pages/about" element={<About />} />
          <Route path="/pages/discography" element={<Discography />} />
          {/* <Route path="/pages/media" element={<Media />} /> */}
          <Route path="/pages/photos" element={<Photos />}>
            <Route path="/pages/photos/:photoId" element={<Photo />} />
          </Route>
          <Route path="/pages/videos" element={<Videos />} />
          <Route path="/pages/contact" element={<Contact />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default App;
