import React, { useEffect } from "react";

import { initializeContact } from "../../reducers/contactReducer";
import { useDispatch, useSelector } from "react-redux";

import StrapiImage from "../StrapiImage";

import { Reveal } from "../Reveal";

import { LoadingScreen } from "../LoadingScreen";

const Contact = () => {
  const contactPageContents = useSelector((state) => state.contact);
  const contacts = contactPageContents.contact;
  const backgroundPhoto = contactPageContents.page_background;

  const showLoadingScreen = !(contacts && backgroundPhoto);
  // const showLoadingScreen = true;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeContact());
  }, []);

  return (
    <>
      {showLoadingScreen && <LoadingScreen mode={"white"} />}

      <Reveal directionFrom="right">
        <div
          className="contact"
          // style={{ display: !showLoadingScreen ? "block" : "none" }}
        >
          <dl>
            {contacts &&
              contacts.map((contact) => (
                <div key={contact.id}>
                  <dt>{contact.name}</dt>
                  <dd>
                    {contact.left_clarification &&
                      `${contact.left_clarification} | `}
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </Reveal>

      {backgroundPhoto && (
        <Reveal directionFrom="left">
          {/* <img
            className="contact-blaz-img"
            src={`/strapi${backgroundPhoto.url}`}
            alt="Blaz Svagan"
          /> */}
          <StrapiImage image={backgroundPhoto} className="contact-blaz-img" />
        </Reveal>
      )}
    </>
  );
};

export default Contact;
