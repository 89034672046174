import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@uidotdev/usehooks";

const Dropdown = ({ text, links, variants }) => {
  const isSmallDevice = useMediaQuery("screen and (max-width: 1230px)");

  const [hover, setHover] = useState(false);
  const hoverOn = () => {
    setHover(true);
  };
  const hoverOff = () => {
    setHover(false);
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      display: "block",
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  const itemAnimation = (index) => ({
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        duration: 0.25,
        delay: index * 0.25,
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        ease: "easeOut",
        duration: 0.2,
      },
    },
  });

  return (
    <motion.li
      className="dropdown"
      onHoverStart={hoverOn}
      onHoverEnd={hoverOff}
      variants={variants}
    >
      <span className={isSmallDevice ? "media-hidden" : ""}>{text}</span>
      <motion.ul
        className="features-menu"
        initial="exit"
        animate={hover ? "enter" : "exit"}
        variants={isSmallDevice ? null : subMenuAnimate}
      >
        {links.map(({ path, text }, index) => (
          <motion.li
            key={path}
            initial="exit"
            animate={hover ? "enter" : "exit"}
            variants={isSmallDevice ? null : itemAnimation(index)}
          >
            <NavLink to={path}>{text}</NavLink>
          </motion.li>
        ))}
      </motion.ul>
    </motion.li>
  );
};

export default Dropdown;
