import { imagePrefixUrl } from "../utils/config";

export const setCssImageSourceFromStrapi = (strapiImage, cssVariableName) => {
  const { url, formats } = strapiImage;

  const srcSet = Object.keys(formats)
    .map(
      (format) =>
        `url("${imagePrefixUrl}${formats[format].url}") ${formats[format].width}w`
    )
    .join(", ");

  const lgImageUrl = formats.xl.url;

  const imageSet = `image-set(${srcSet})`;
  const webkitImageSet = `-webkit-${imageSet}`;
  const fallbackUrl = `${imagePrefixUrl}${lgImageUrl}`;

  document.documentElement.style.setProperty(
    `--${cssVariableName}-image`,
    `url("${fallbackUrl}")`
  );
  document.documentElement.style.setProperty(
    `--${cssVariableName}-image-set`,
    imageSet
  );
  document.documentElement.style.setProperty(
    `--${cssVariableName}-webkit-image-set`,
    webkitImageSet
  );
};
