import React from "react";
import { Link, NavLink, useMatch } from "react-router-dom";

import { openMenu, closeMenu, toggleMenu } from "../../reducers/menuReducer";
import { useDispatch, useSelector } from "react-redux";

import { useAnimate, stagger, motion } from "framer-motion";

import Dropdown from "./Dropdown";

import "./index.css";

import { AnimatedHamburgerIcon } from "./AnimatedHamburgerIcon";

const RegularItalicWidthNormalizer = ({ children }) => {
  return (
    <span className="x">
      <span className="y z">{children}</span>
      <span className="y p">{children}</span>
      <span className="y">{children}</span>
    </span>
  );
};

const Navbar = () => {
  const hambOpen = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const open = () => dispatch(openMenu());
  const close = () => dispatch(closeMenu());
  const toggle = () => dispatch(toggleMenu());

  const isFirstPage = useMatch("/pages") !== null;

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
        delayChildren: 1,
      },
    },
  };

  return (
    <>
      <nav className="menu-closed">
        <span className="name-hamburger-container">
          <span className="empty-span"></span>
          <span id="nav-name">
            <Link
              to="/pages"
              className={"title" + (isFirstPage ? " title-hidden" : "")}
              onClick={close}
            >
              Blaž Švagan
            </Link>
          </span>
          <AnimatedHamburgerIcon isOpen={hambOpen} onClick={toggle} />
        </span>

        <motion.ul
          className="menu"
          initial="closed"
          animate="open"
          exit="closed"
          variants={sideVariants}
        >
          <motion.li onClick={close} variants={itemVariants}>
            <RegularItalicWidthNormalizer>
              <NavLink to="/pages/about">About</NavLink>
            </RegularItalicWidthNormalizer>
          </motion.li>
          <motion.li onClick={close} variants={itemVariants}>
            <RegularItalicWidthNormalizer>
              <NavLink to="/pages/discography">Discography</NavLink>
            </RegularItalicWidthNormalizer>
          </motion.li>
          <Dropdown
            text={"Media"}
            links={[
              { path: "/pages/photos", text: "Photos" },
              { path: "/pages/videos", text: "Videos" },
            ]}
            variants={itemVariants}
          />
          <motion.li onClick={close} variants={itemVariants}>
            <RegularItalicWidthNormalizer>
              <NavLink to="/pages/contact">Contact</NavLink>
            </RegularItalicWidthNormalizer>
          </motion.li>
        </motion.ul>
      </nav>
    </>
  );
};

export default Navbar;
