import { configureStore } from "@reduxjs/toolkit";
import homepageReducer from "./reducers/homepageReducer";
import aboutReducer from "./reducers/aboutReducer";
import menuReducer from "./reducers/menuReducer";
import discographyReducer from "./reducers/discographyReducer";
import photosReducer from "./reducers/photosReducer";
import videosReducer from "./reducers/videosReducer";
import contactReducer from "./reducers/contactReducer";

const store = configureStore({
  reducer: {
    homepage: homepageReducer,
    menu: menuReducer,
    about: aboutReducer,
    discography: discographyReducer,
    photos: photosReducer,
    videos: videosReducer,
    contact: contactReducer,
  },
});

export default store;
