import { createSlice } from "@reduxjs/toolkit";
import videosService from "../services/videos";

const videosSlice = createSlice({
  name: "videos",
  initialState: [],
  reducers: {
    setVideos(state, action) {
      return action.payload;
    },
  },
});

const { setVideos } = videosSlice.actions;

export const initializeVideos = () => {
  return async (dispatch) => {
    const videos = await videosService.getVideos();
    dispatch(setVideos(videos.video));
  };
};

export default videosSlice.reducer;
