import React from "react";
import { imagePrefixUrl } from "../utils/config";

const StrapiImage = ({ image, ...remainingProps }) => {
  const { url, formats, alternativeText } = image;

  const srcSet = Object.keys(formats)
    .map(
      (format) =>
        `${imagePrefixUrl}${formats[format].url} ${formats[format].width}w`
    )
    .join(", ");


  return (
    <img
      src={`${imagePrefixUrl}${url}`}
      srcSet={srcSet}
      alt={alternativeText || "Strapi image"}
      {...remainingProps}
    />
  );
};

export default StrapiImage;
