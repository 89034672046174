import { createSlice } from "@reduxjs/toolkit";
import contactService from "../services/contact";

const contactSlice = createSlice({
  name: "contact",
  initialState: {},
  reducers: {
    setContact(state, action) {
      return action.payload;
    },
  },
});

const { setContact } = contactSlice.actions;

export const initializeContact = () => {
  return async (dispatch) => {
    const contact = await contactService.getContact();
    dispatch(setContact(contact));
  };
};

export default contactSlice.reducer;
