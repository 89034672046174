// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./AtypDisplay-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./AtypDisplay-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./AtypDisplay-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./AtypDisplay-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./AtypDisplay-BoldItalic.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./AtypDisplay-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./AtypDisplay-BoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./AtypDisplay-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Atyp Display";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: local("Atyp Display Bold"), local("AtypDisplay-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Atyp Display";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  src: local("Atyp Display Bold Italic"), local("AtypDisplay-BoldItalic"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("truetype");
  font-weight: bold;
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./static/fonts/Atyp/atyp.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4CAAkC;EAClC;;;8DAGkD;EAClD,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,4CAAwC;EACxC;;;8DAGwD;EACxD,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Atyp Display\";\n  src: url(\"./AtypDisplay-Bold.eot\");\n  src: local(\"Atyp Display Bold\"), local(\"AtypDisplay-Bold\"),\n    url(\"./AtypDisplay-Bold.woff2\") format(\"woff2\"),\n    url(\"./AtypDisplay-Bold.woff\") format(\"woff\"),\n    url(\"./AtypDisplay-Bold.ttf\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Atyp Display\";\n  src: url(\"./AtypDisplay-BoldItalic.eot\");\n  src: local(\"Atyp Display Bold Italic\"), local(\"AtypDisplay-BoldItalic\"),\n    url(\"./AtypDisplay-BoldItalic.woff2\") format(\"woff2\"),\n    url(\"./AtypDisplay-BoldItalic.woff\") format(\"woff\"),\n    url(\"./AtypDisplay-BoldItalic.ttf\") format(\"truetype\");\n  font-weight: bold;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
