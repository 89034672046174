import { createSlice } from "@reduxjs/toolkit";
import discographyService from "../services/discography";

const discographySlice = createSlice({
  name: "discography",
  initialState: [],
  reducers: {
    setDiscography(state, action) {
      return action.payload;
    },
  },
});

const { setDiscography } = discographySlice.actions;

export const initializeDiscography = () => {
  return async (dispatch) => {
    const discography = await discographyService.getDiscography();
    dispatch(setDiscography(discography));
  };
};

export default discographySlice.reducer;
